"use client";

import clsx from "clsx";
import React from "react";
import { useInstantSearch } from "react-instantsearch";
import styles from "./index.module.css";
const indexCategory = {
  products: "products",
  storyblok_journal: "stories",
  retailers: "retailers",
};

const SearchResultContainer = ({
  index,
  children,
  isSearchPage,
  isModal,
}: {
  isSearchPage?: boolean;
  isModal?: boolean;
  index: string;
  children: React.ReactNode;
}) => {
  const {
    results: { hits },
  } = useInstantSearch();
  if (hits.length === 0) {
    if (isModal)
      return (
        <>
          <div
            className={clsx(
              styles.emptySearchContainer,
              isSearchPage && styles.emptySearchResultPage,
            )}
          >
            <p>No product results found.</p>
            <p style={{ marginTop: "var(--spacing-16" }}>
              Try a different search or press Enter to search retailers and
              articles.
            </p>
          </div>
        </>
      );

    return (
      <div className={clsx(isSearchPage && styles.emptySearchResultPage)}>
        {" "}
        <p>No results found. Try a different search.</p>
      </div>
    );
  }

  return (
    <section style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
      {isSearchPage && (
        <p>
          {hits.length} {indexCategory[index as keyof typeof indexCategory]}
        </p>
      )}
      {children}
    </section>
  );
};
export default SearchResultContainer;
