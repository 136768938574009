type Props = {};

const EnterQuery = (props: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M8.66856 4.39961H10.9352V10.2663H4.3009L5.61806 8.94912L5.05237 8.38343L2.76953 10.6663L5.05237 12.9491L5.61806 12.3834L4.3009 11.0663H11.7352V3.59961H8.66856V4.39961Z"
          fill="#0E0E0E"
        />
      </g>
    </svg>
  );
};

export default EnterQuery;
