"use client";

import { ProductCard } from "@/components/atoms";
import EnterQuery from "@/icons/EnterQuery";
import formatProductCard from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import useDevice from "@/lib/hooks/useDevice";
import { useCmdK } from "@/lib/hooks/useKeyboardShortcut";
import useModal from "@/lib/hooks/useModal";
import ProductSectionSkeleton from "@/lib/plp/components/ProductsSection/ProductSectionSkeleton";
import { useEffect } from "react";
import { Index, useInstantSearch, useSearchBox } from "react-instantsearch";
import { StoryblokStory } from "storyblok-generate-ts";
import ProductGrid from "../ProductGrid";
import AlgoliaSearchInput from "./AlgoliaSearchInput";
import styles from "./index.module.css";
import PopularSearches from "./PopularSearches";
import { RecommendedProductCards } from "./RecommendedProducts";
import SearchResultContainer from "./SearchResultContainer";
import Suggestions from "./Suggestions";

/**
 * Chrome is being ridiculous and keeps the previous render while painting the new one.
 * Adding this element to the DOM to force the browser to paint the new render.
 */
const DoNotRemove = () => (
  <p
    style={{
      color: "var(--color-all-white)",
      userSelect: "none",
      pointerEvents: "none",
    }}
    aria-hidden="true"
  >
    .
  </p>
);

export const ProductCards = ({
  isSearchPage,
  isModal,
}: {
  isSearchPage?: boolean;
  isModal?: boolean;
}) => {
  const {
    results: { hits },

    status,
  } = useInstantSearch();
  const { mobile } = useDevice();
  const results = isSearchPage
    ? hits
    : mobile
      ? hits.slice(0, 6)
      : hits.slice(0, 5);

  if (status === "loading" && isSearchPage) return <ProductSectionSkeleton />;
  return (
    <SearchResultContainer
      isSearchPage={isSearchPage}
      isModal={isModal}
      index="products"
    >
      <ProductGrid className={isSearchPage ? "" : styles.productGrid} id="grid">
        {results?.map((product, i: number) => {
          return (
            <ProductCard
              product={formatProductCard(product)}
              key={`product-${product?.core?.slug}-${i}`}
              priority={i < 5}
            />
          );
        })}
      </ProductGrid>
      {isModal && <DoNotRemove />}
    </SearchResultContainer>
  );
};
export function Search({ bloks }: { bloks: StoryblokStory<unknown>[] }) {
  const { query } = useSearchBox();
  const { toggleModal, closeModal, isOpen } = useModal("search", () => {});
  useCmdK(toggleModal);

  useEffect(() => {
    if (!isOpen) return;
    const down = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", down);
    return () => {
      document.removeEventListener("keydown", down);
    };
  }, [closeModal, isOpen]);

  const hasQuery = query && query.length > 0;

  return (
    <div className={styles.wrapper}>
      <AlgoliaSearchInput />
      <div className={styles.hintContainer}>
        <p>
          Press <i>Enter</i> to view all search results
        </p>
        <EnterQuery />
      </div>
      <Index indexName="products">
        <div className={styles.grid}>
          {hasQuery ? (
            <>
              <Suggestions query={query} />
              <div className={styles.results}>
                <ProductCards isModal />
              </div>
            </>
          ) : (
            <>
              <PopularSearches blok={bloks[0]} />
              <div>
                <p className={styles.title}>Our recommendations</p>
                <RecommendedProductCards blok={bloks[1]} />
              </div>
            </>
          )}
        </div>
      </Index>
    </div>
  );
}
