import clsx from "clsx";

import Skeleton from "../Skeleton";
import LocalisedLink from "./LocalisedLink";
import styles from "./product-card.module.css";

export const ProductCardSkeleton = () => {
  return (
    <div className={clsx(styles.card__container)}>
      <LocalisedLink slug={"#"}>
        <div className={clsx(styles.card__image)}>
          <div className={clsx(styles.product__image)}></div>
        </div>
        <div className={styles.card__description}>
          <div className={styles.product__material}>
            <Skeleton height="16px" width="80px" />
          </div>
          <div className={styles.product__meta}>
            <span className={styles.product__structure}>
              <Skeleton height="16px" width="100px" />
            </span>
            {/* Price */}
            <Skeleton height="16px" width="50px" />
          </div>
          <div>
            <div className={styles.card__swatches}>
              <>
                <div
                  className={clsx(styles.swatch)}
                  style={{ background: "lightgray" }}
                />
                <div
                  className={clsx(styles.swatch)}
                  style={{ background: "lightgray" }}
                />
                <div
                  className={clsx(styles.swatch)}
                  style={{ background: "lightgray" }}
                />

                <p className={styles.card__swatches_more}>+{2} more</p>
              </>
            </div>
          </div>
        </div>
      </LocalisedLink>
    </div>
  );
};

export default ProductCardSkeleton;
