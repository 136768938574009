import ProductCardSkeleton from "@/components/atoms/ProductCard/Skeleton";
import ProductGrid from "@/components/molecules/ProductGrid";

const ProductSectionSkeleton = () => {
  return (
    <section style={{ display: "flex", flexDirection: "column", gap: "6rem" }}>
      <>
        <ProductGrid id="grid">
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
          <ProductCardSkeleton />
        </ProductGrid>
      </>
    </section>
  );
};

export default ProductSectionSkeleton;
