"use client";
import { Skeleton } from "@/components/atoms";
import useModal from "@/lib/hooks/useModal";
import { useRouter } from "next/navigation";
import { StoryblokStory } from "storyblok-generate-ts";
import styles from "./index.module.css";

const PopularSearches = ({ blok }: { blok: StoryblokStory<any> }) => {
  const router = useRouter();
  const { closeModal } = useModal("search", () => {});

  const popularSearchTerms = Object.keys(blok?.content?.body[0])
    .filter((key) => key !== "_uid" && key !== "component")
    .map((key) => blok?.content?.body[0][key]);

  if (!blok?.content?.body) {
    return (
      <div className={styles.popularSearches}>
        <p className={styles.title}>Popular searches</p>
        <ul>
          {Array.from(Array(5).keys()).map((i) => (
            <Skeleton key={i} height="25px" width="100%" />
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className={styles.popularSearches}>
      <p className={styles.title}>Popular searches</p>
      <ul>
        {popularSearchTerms?.map((search: string) => (
          <li
            onClick={() => {
              router.push(`/search?q=${search}`);
              setTimeout(() => {
                closeModal();
              }, 400);
            }}
            key={search}
          >
            {search}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PopularSearches;
