"use client";

import { Button } from "@/components/atoms";
import Close from "@/icons/Close";
import useModal from "@/lib/hooks/useModal";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useRef, useState } from "react";
// @ts-ignore-next-line
import { useSearchBox } from "react-instantsearch";
import styles from "./index.module.css";

const AlgoliaSearchInput = () => {
  const { query, refine } = useSearchBox();
  const [inputValue, setInputValue] = useState(query);
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(
    null,
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const { isOpen, closeModal } = useModal("search", () => {}, false);

  const setQuery = useCallback(
    (newQuery: string) => {
      setInputValue(newQuery);
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      const newTimer = setTimeout(() => {
        refine(newQuery);
      }, 150);
      setDebounceTimer(newTimer);
    },
    [refine, debounceTimer],
  );

  useEffect(() => {
    setInputValue(query);
  }, [query]);

  useEffect(() => {
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, [debounceTimer]);

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  return (
    <div>
      <form
        action=""
        role="search"
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (inputRef.current) {
            inputRef.current.blur();
          }
          if (isOpen) {
            router.push("/search" + "?q=" + inputValue);
            closeModal();
          }
        }}
        onReset={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setQuery("");
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <div className={styles.modalHeader}>
          <input
            className={styles.input}
            ref={inputRef}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            placeholder="Search"
            spellCheck={false}
            maxLength={512}
            type="search"
            value={inputValue}
            onChange={(event) => {
              setQuery(event.currentTarget.value);
            }}
            autoFocus
          />
          <input type="submit" hidden />
          <Button
            color="transparent"
            onClick={closeModal}
            rounded
            label="close"
          >
            <Close />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AlgoliaSearchInput;
