"use client";

import useModal from "@/lib/hooks/useModal";
import clsx from "clsx";
import { useRouter } from "next/navigation";
import { useMemo } from "react";
import { useInstantSearch } from "react-instantsearch";
import styles from "./index.module.css";

const Suggestions = ({ query }: { query: string }) => {
  const {
    results: { hits },
  } = useInstantSearch();
  const router = useRouter();
  const { closeModal } = useModal("search", () => {}, false);

  const uniqueRecommendations = useMemo(() => {
    const uniqueRecommendations: Set<string> = new Set();
    hits.forEach((hit) => {
      uniqueRecommendations.add(hit?.pr_shape.value);
    });
    return uniqueRecommendations;
  }, [hits]);

  return (
    <div className={clsx(styles.popularSearches, styles.suggestions)}>
      <p className={styles.title}>Suggestions</p>
      <ul>
        {Array.from(uniqueRecommendations)?.map((shape) => {
          return (
            <li
              onClick={() => {
                router.push("/search?q=" + shape);
                setTimeout(() => {
                  closeModal();
                }, 400);
              }}
              key={shape}
            >
              {shape}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Suggestions;
